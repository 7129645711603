import { useState } from "react";
import { Button } from "react-bootstrap";
import "../styles/FeedbackInfo.css";

interface IFeedbackInfo {
  icon: string;
  feedback: string;
  fio: string;
  position: string;
  comName: string;
}

export const FeedbackInfo = ({ icon, feedback, comName }: IFeedbackInfo) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className="m_fginf_main" aria-hidden="true">
      <div className="m_fginf_name_con">
        <div className="m_fginf_name">{comName}</div>
        <img src={icon} alt="" className="m_fginf_icon" />
      </div>
      <div
        aria-hidden="true"
        className={
          "m_fginf_textcomp " + (showMore ? "m_fginf_textcomp_full" : "")
        }
      >
        {feedback}
      </div>
      <Button
        variant="default"
        className="m_fginf_btn_show_more"
        onClick={() => setShowMore(!showMore)}
      >
        {showMore ? "Скрыть" : "Показать ещё"}
      </Button>
      {/* <FeedbackAuthorContainer fio={fio} position={position} /> */}
    </div>
  );
};
